import React from 'react'
import { useTranslation, I18nContext, Trans } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title={t('title')} language={i18n.language} />
          <p>
              <Trans>NOT FOUND</Trans>
          </p>
          <p>
              <Trans>You just hit a route that doesn&#39;t exist :(</Trans>
          </p>
      </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "404", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
